const TopBarMenuData = [
  {
    isActive: true,
    section: 'Formations',
    elements: [
      {
        isActive: true,
        url: '/apprendre-a-coder-gratuitement/',
        title: 'Préparation HTML/CSS/JS',
        subtitle: [
          {
            item: 'E-learning',
            isBold: true,
          },
          {
            item: ' - Formation gratuite',
          },
        ],
      },
      {
        isActive: true,
        url: '/cours-informatique/formation-developpeur-web/',
        title: 'Développeur Web (Bac+2)',
        subtitle: [
          {
            item: 'E-learning + mentoring',
            isBold: true,
          },
          {
            item: ' - 3 à 6 mois',
          },
        ],
        isNew: true,
      },
      {
        isActive: true,
        url: '/bootcamp-javascript/formation-developpeur-web-mobile/',
        title: 'Développeur Web et Mobile (Bac+3/4)',
        subtitle: [
          {
            item: 'Bootcamp à temps plein',
            isBold: true,
          },
          {
            item: ' - 10 semaines',
          },
        ],
      },
      {
        isActive: true,
        url: '/bootcamp-javascript/formation-developpeur-web-mobile-temps-partiel/',
        title: 'Développeur Web et Mobile (Bac+3/4)',
        subtitle: [
          {
            item: 'Bootcamp à temps partiel ',
            isBold: true,
          },
          {
            item: '- 6 mois',
          },
        ],
      },
      {
        isActive: true,
        url: '/formation-alternance/concepteur-developpeur-applications/',
        title: 'Développeur Web et Mobile (Bac+3/4)',
        subtitle: [
          {
            item: 'Formation en alternance',
            isBold: true,
          },
          {
            item: ' - 15 à 17 mois',
          },
        ],
      },
      {
        isActive: false,
        url: '/bootcamp-web3/formation-developpeur-blockchain/',
        title: 'Développeur Blockchain',
        subtitle: [
          {
            item: 'Bootcamp à temps partiel',
            isBold: true,
          },
          {
            item: ' - 9 semaines',
          },
        ],
        isNew: true,
      },
    ],
    // width: 370,
  },
  {
    isActive: false,
    section: 'Alternance',
    elements: [
      {
        isActive: true,
        url: '/formation-alternance/-concepteur-developpeur-d-applications/',
        title: 'Développeur Web et Mobile (Bac+3/4)',
        subtitle: [
          {
            item: 'Formation en alternance',
            isBold: true,
          },
          {
            item: ' - 15 à 17 mois',
          },
        ],
      },
    ],
    // width: 370,
  },
  {
    isActive: true,
    section: 'Entreprises',
    link: '/formations-professionnelles/',
    elements: [
      {
        isActive: true,
        url: '/formation/react-redux/',
        title: 'Formation React + Redux',
        subtitle: [
          {
            item: 'Temps plein',
            isBold: true,
          },
          {
            item: ' - 5 jours',
          },
        ],
      },
      {
        isActive: true,
        url: '/formation/react-native/',
        title: 'Formation React Native',
        subtitle: [
          {
            item: 'Temps plein',
            isBold: true,
          },
          {
            item: ' - 5 jours',
          },
        ],
      },
      {
        isActive: true,
        url: '/formation/react-avance/',
        title: 'Formation React Avancé',
        subtitle: [
          {
            item: 'Temps plein',
            isBold: true,
          },
          {
            item: ' - 5 jours',
          },
        ],
        isNew: true,
      },
      {
        isActive: true,
        url: '/formation/typescript/',
        title: 'Formation TypeScript',
        subtitle: [
          {
            item: 'Temps plein',
            isBold: true,
          },
          {
            item: ' - 3 jours',
          },
        ],
      },
      {
        isActive: true,
        url: '/formation/node-js/',
        title: 'Formation Node.js',
        subtitle: [
          {
            item: 'Temps plein',
            isBold: true,
          },
          {
            item: ' - 3 jours',
          },
        ],
      },
    ],
  },
  {
    isActive: true,
    section: 'Recruteurs',
    link: '/recruteurs/',
  },
  {
    isActive: true,
    section: 'Événements',
    link: '/evenements/',
  },
  {
    isActive: true,
    section: 'Blog',
    link: '/blog/',
  },
];

export { TopBarMenuData };
